import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export default styled(({ className, children }) => {
  return (
    <motion.div
      className={className}
      variants={{
        closed: { rotate: 0, top: 0 },
        open: { rotate: -45, top: '50%' },
      }}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 20,
        mass: 2,
      }}
    />
  );
})`
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.secondary};
  position: absolute;
  top: 0;
  left: 0;

  ${props => props.light && css`
    background-color: ${props.theme.textDark};
  `}
`;
