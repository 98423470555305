export const hoverSpringSmall = {
  type: 'spring',
  damping: 10,
  stiffness: 200,
  mass: 1,
};

export const hoverSpringMedium = {
  type: 'spring',
  damping: 20,
  stiffness: 200,
  mass: 2,
};