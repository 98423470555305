import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

import { BACKGROUND_STATE } from '../constants';

export default styled.div`
  background-color: ${props => props.theme.bg2};
  transition-property: background-color;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  ${media.medium`

    ${props =>
      props.light &&
      css`
        background-color: #fff;
      `}
  `};

  /* ${({ blank, isSmall }) =>
    blank && !isSmall &&
    css`
      background-color: #fff !important;
    `} */
`;
