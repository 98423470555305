import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  WebGLRenderer,
  OrthographicCamera,
  Scene,
  PlaneBufferGeometry,
  ShaderMaterial,
  Mesh,
  Vector3,
  Vector4,
  Texture,
  Color,
  LinearFilter,
} from 'three';

import { ThemeContext } from 'utils/theme';
import { sizes, isIE11 } from 'utils/detection';

import { BACKGROUND_STATE } from './constants';
import Wrapper from './styled/Wrapper';
import Canvas from './styled/Canvas';
import shaderFrag from './shaders/background-8.frag';
import logo from './logo.svg.txt';
import logoMedium from './logo-medium.svg.txt';
import logoBlank from './logo-blank.svg.txt';

export default class Background extends PureComponent {

  static defaultProps = {
    state: BACKGROUND_STATE.INTRO,
    light: false,
    dark: false,
    mouseSpeed: 3,
    bgColorSpeed: 4,
    blank: false,
  };

  static contextType = ThemeContext;

  state = {
    dpr: 1,
  };

  componentDidMount() {
    const { blank, dark } = this.props;

    this.lastTime = 0;
    this.mousePos = { x: 0, y: 0 };
    this.targetMousePos = { x: 0, y: 0 };
    this.targetBgColor = new Color(blank && !dark ? this.context.bgBlank : this.context.bg2);
    this.targetLogoDarkAlpha = 1;
    this.targetLogoLightAlpha = 1;
    this.logoDarkAlpha = 1;
    this.logoLightAlpha = 1;
    this.bgColor = new Color(blank && !dark ? this.context.bgBlank : this.context.bg2);
    this.logos = [];
    this.initThree();
    this.loadTexture();

    window.addEventListener('resize', this.onResize);
    window.addEventListener('mousemove', this.onMouseMove);

    window.addEventListener('touchstart', this.onTouchMove);
    window.addEventListener('touchmove', this.onTouchMove);
    this.rafId = requestAnimationFrame(this.onFrame);

    this.onResize();
  }

  componentDidUpdate(prevProps) {
    const { light, dark, state } = this.props;

    if (light !== prevProps.light || state !== prevProps.state || dark !== prevProps.dark) {
      this.targetBgColor = new Color(
        light
          ? this.context.bgLight
          : state === BACKGROUND_STATE.INTRO
          ? this.context.bg1
          : this.context.bg2,
      );

      if (state === BACKGROUND_STATE.CLEAN) {
        this.targetLogoDarkAlpha = 0;
        this.targetLogoLightAlpha = 0;
      } else if (light) {
        this.targetLogoDarkAlpha = 0;
        this.targetLogoLightAlpha = 1;
      } else {
        this.targetLogoDarkAlpha = 1;
        this.targetLogoLightAlpha = 0;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('touchstart', this.onTouchMove);
    window.removeEventListener('touchmove', this.onTouchMove);
  }

  initThree() {
    this.renderer = new WebGLRenderer({ canvas: this.canvasEl, antialias: true });
    this.scene = new Scene();
    this.camera = new OrthographicCamera(-1, 1, 1, -1, -1, 1);

    this.textureCanvas = document.createElement('canvas');
    this.textureCanvas.width = 512;
    this.textureCanvas.height = 512;
    this.textureContext = this.textureCanvas.getContext('2d');
    this.texture = new Texture(this.textureCanvas);
    this.texture.minFilter = LinearFilter;

    this.uniforms = {
      iTime: { value: 0 },
      iResolution: { value: new Vector3() },
      iMouse: { value: new Vector4() },
      iChannel0: { value: this.texture },
    };

    this.geometry = new PlaneBufferGeometry(2, 2);
    this.material = new ShaderMaterial({
      fragmentShader: shaderFrag,
      uniforms: this.uniforms,
    });

    this.mesh = new Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  }

  createLogoImage(baseLogo, color) {
    const blob = new Blob([baseLogo.replace(/#f0f0f0/g, color)], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.isLoaded = false;
    img.addEventListener(
      'load',
      () => {
        img.isLoaded = true;
        URL.revokeObjectURL(url);
      },
      { once: true },
    );
    img.src = url;

    return img;
  }

  loadTexture() {
    this.textureImageDark = this.createLogoImage(logo, '#0c2234');
    this.textureImageLight = this.createLogoImage(logo, '#d7fffc');
    this.textureImageMediumDark = this.createLogoImage(logoMedium, '#0c2234');
    this.textureImageMediumLight = this.createLogoImage(logoMedium, '#d7fffc');
    this.textureImageBlank = this.createLogoImage(logoBlank, '#d7fffc;');
  }

  dispose() {
    cancelAnimationFrame(this.rafId);
  }

  refCanvas = el => {
    this.canvasEl = el;

    if (el && this.renderer) {
      this.renderer.domElement = el;
    } else {
      this.dispose();
    }
  };

  updateTexture = () => {
    const { blank } = this.props;
    const { dpr } = this.state;

    this.textureContext.globalAlpha = 1;
    this.textureContext.fillStyle = `#${this.bgColor.getHexString()}`;
    this.textureContext.fillRect(0, 0, this.textureCanvas.width, this.textureCanvas.height);

    // Draw dark logo
    const isMedium = window.innerWidth >= sizes.medium;
    const darkLogo = isMedium ? this.textureImageMediumDark : this.textureImageDark;
    const lightLogo = isMedium ? this.textureImageMediumLight : this.textureImageLight;

    if (!darkLogo.isLoaded || !lightLogo.isLoaded) {
      return;
    }

    if (blank) {
      const x = 0;
      const y = 0;
      const width = (isMedium ? 180 : 113) * dpr;
      const height = (isMedium ? 173 : 109) * dpr;

      this.textureContext.globalAlpha = 1;
      !isIE11() && this.textureContext.drawImage(this.textureImageBlank, x, y, width, height);
    } else {
      const width = this.textureCanvas.width;
      const height = this.textureCanvas.height;

      if (this.logoDarkAlpha > 0.01) {
        this.textureContext.globalAlpha = this.logoDarkAlpha;
        !isIE11() && this.textureContext.drawImage(darkLogo, 0, 0, width, height);
      }

      if (this.logoLightAlpha > 0.01) {
        this.textureContext.globalAlpha = this.logoLightAlpha;
        !isIE11() && this.textureContext.drawImage(lightLogo, 0, 0, width, height);
      }
    }

    this.texture.needsUpdate = true;
  };

  updateBgColor(dt) {
    const { bgColorSpeed } = this.props;

    const timeFactor = Math.min(bgColorSpeed * dt, 1);
    this.bgColor = this.bgColor.lerp(this.targetBgColor, timeFactor);
  }

  updateLogoAlpha(dt) {
    const { bgColorSpeed } = this.props;

    const timeFactor = Math.min(bgColorSpeed * dt, 1);
    this.logoDarkAlpha += (this.targetLogoDarkAlpha - this.logoDarkAlpha) * timeFactor;
    this.logoLightAlpha += (this.targetLogoLightAlpha - this.logoLightAlpha) * timeFactor;
  }

  updateMouse(dt) {
    const { mouseSpeed } = this.props;

    const timeFactor = Math.min(mouseSpeed * dt, 1);
    this.mousePos.x += (this.targetMousePos.x - this.mousePos.x) * timeFactor;
    this.mousePos.y += (this.targetMousePos.y - this.mousePos.y) * timeFactor;
  }

  updateUniforms(time) {
    this.uniforms.iTime.value = time;
    this.uniforms.iMouse.value = new Vector4(this.mousePos.x, this.mousePos.y, 0, 0);
  }

  updateThree() {
    this.renderer.render(this.scene, this.camera);
  }

  onMouseMove = e => {
    const { dpr } = this.state;

    this.targetMousePos.x = e.clientX * dpr;
    this.targetMousePos.y = (window.innerHeight - e.clientY) * dpr;
  };

  onTouchMove = e => {
    const { dpr } = this.state;

    this.targetMousePos.x = e.touches[0].clientX * dpr;
    this.targetMousePos.y = (window.innerHeight - e.touches[0].clientY) * dpr;
  };

  onFrame = time => {
    const t = time / 1000;
    const dt = t - this.lastTime;
    this.rafId = requestAnimationFrame(this.onFrame);

    this.updateBgColor(dt);
    this.updateLogoAlpha(dt);
    this.updateTexture();
    this.updateMouse(dt);
    this.updateUniforms(t);
    this.updateThree();

    this.lastTime = t;
  };

  onResize = () => {
    const dpr = window.innerWidth >= sizes.medium ? 1 : Math.min(window.devicePixelRatio, 2);
    this.setState({ dpr });

    const width = window.innerWidth * dpr;
    const height = window.innerHeight * dpr;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.uniforms.iResolution.value.set(width, height, 1);
    this.camera.updateProjectionMatrix();
    this.textureCanvas.width = width;
    this.textureCanvas.height = height;
    this.updateTexture();
  };

  render() {
    const { className, state, light, blank } = this.props;
    const { dpr } = this.state;

    return (
      <Wrapper className={className} theme={this.context} state={state} light={light} blank={blank}>
        <Canvas ref={this.refCanvas} dpr={dpr} />
      </Wrapper>
    );
  }
}
