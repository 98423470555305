import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'utils/detection';

export default styled(({ className, children, hidden }) => {
  return (
    <motion.ul
      className={className}
      variants={{
        visible: { transition: { staggerChildren: 0.05 } },
        hidden: { transition: { staggerChildren: 0.05 } },
      }}
      initial="hidden"
      animate={hidden ? 'hidden' : 'visible'}
    >
      {React.Children.toArray(children)}
    </motion.ul>
  );
})`
  position: absolute;
  bottom: 40px;
  left: 40px;
  list-style: none;
  margin: 0;
  padding: 0;

  ${media.medium`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    left: 0;
    right: 0;
    top: 50%;
    bottom: auto;
    transform: translate(0, -50%);
  `}
`;
