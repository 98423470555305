import styled, { css } from 'styled-components';

import { media } from 'utils/detection';

export default styled.p`
  display: none;

  ${media.medium`
    display: block;
    color: ${props => props.theme.textLight};
    font-family: 'Galano Classic';
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
    position: absolute;
    top: 22px;
    left: 2px;
    transform: translate(-100%, -50%) rotate(-90deg);
    transform-origin: 100% 0%;
    transition-property: opacity, visibility, color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    ${props => props.light && css`
      color: ${props.theme.textDark};
    `}
  `}

  ${props => props.hidden && css`
    display: block;
    opacity: 0;
    visibility: hidden;
  `}
`;
