import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'utils/detection';
import { hoverSpringMedium } from 'utils/motion';

class NavLink extends React.PureComponent {

  render() {
    const { className, children, ...restProps } = this.props;

    return (
      <motion.a
        className={className}
        whileHover={{ x: -20 }}
        transition={hoverSpringMedium}
        {...restProps}
      >
        {React.Children.toArray(children)}
      </motion.a>
    );
  }
}

export default styled(NavLink)`
  color: ${props => props.theme.textLight};
  font-family: 'Galano Classic';
  text-decoration: none;
  font-size: 39px;
  font-weight: 400;
  line-height: 72px;
  cursor: pointer;

  ${media.medium`
    display: block;
    font-size: 3vw;
  `}

  ${props => props.light && css`
    color: ${props => props.theme.textDark};
  `}

  ${props => props.isActive && css`
    pointer-events: none;
    opacity: 0.3;
  `}
`;
