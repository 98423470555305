import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';
import PageLinkModel from 'models/PageLink';

import Link from 'next/link';

import Wrapper from './styled/Wrapper';
import NavLinks from './styled/NavLinks';
import NavItem from './styled/NavItem';
import NavLink from './styled/NavLink';

export default class BurgerMenu extends PureComponent {


  static defaultProps = {
    hidden: false,
    light: false,
  };

  static contextType = ThemeContext;

  state = {
    currentRoute: null,
  };

  componentDidMount() {
    const { router } = this.props;

    this.setState({ currentRoute: router.route });
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props;

    if (hidden !== prevProps.hidden) {
      if (hidden) {
        this.enableScroll();
      } else {
        this.preventScroll();
      }
    }
  };

  componentWillUnmount() {
    this.enableScroll();
  }

  preventScroll() {
    Object.assign(document.body.style, { overflowY: 'hidden' });
  }

  enableScroll() {
    Object.assign(document.body.style, { overflowY: 'visible' });
  }

  render() {
    const { className, items, hidden, light } = this.props;
    const { currentRoute } = this.state;

    return (
      <Wrapper className={className} hidden={hidden}>
        <NavLinks hidden={hidden}>
          {items.sort((a,b) => a.idx-b.idx).map(({ name, url }) => (
            <NavItem key={name}>
              <Link href={url} passHref>
                <NavLink theme={this.context} light={light} isActive={url === currentRoute}>
                  {name}
                </NavLink>
              </Link>
            </NavItem>
          ))}
        </NavLinks>
      </Wrapper>
    );
  }
}
