import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export default styled(({ className, children, hidden }) => {
  return (
    <motion.li
      className={className}
      variants={{
        hidden: { y: -80, opacity: 0 },
        visible: { y: 0, opacity: 1},
      }}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 20,
        mass: 2,
      }}
    >
      {React.Children.toArray(children)}
    </motion.li>
  );
})`
  margin: 0;
  padding: 0;
`;
