import styled, { css } from 'styled-components';

export default styled.div`
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  ${props => props.hidden && css`
    display: block;
    opacity: 0;
    visibility: hidden;
  `}
`;
