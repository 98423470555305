import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'utils/theme';

import Wrapper from './styled/Wrapper';
import LineTop from './styled/LineTop';
import LineMiddle from './styled/LineMiddle';
import LineBottom from './styled/LineBottom';
import Label from './styled/Label';

export default class BurgerButton extends PureComponent {

  static contextType = ThemeContext;

  static defaultProps = {
    isActive: false,
    labelOpen: 'menu',
    labelClose: 'close',
    light: false,
  };

  render() {
    const { className, isActive, labelOpen, labelClose, light, ...restProps } = this.props;

    return (
      <Wrapper className={className} {...restProps} isActive={isActive}>
        <LineTop theme={this.context} light={light} />
        <LineMiddle theme={this.context} light={light} isActive={isActive} />
        <LineBottom theme={this.context} light={light} isActive={isActive} />
        <Label theme={this.context} light={light} hidden={isActive}>{labelOpen}</Label>
        <Label theme={this.context} light={light} hidden={!isActive}>{labelClose}</Label>
      </Wrapper>
    );
  }
}
