import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'utils/detection';

export default styled(({ className, children, isActive, ...restProps }) => {
  return (
    <motion.button
      className={className}
      {...restProps}
      initial="closed"
      animate={isActive ? 'open' : 'closed'}
    >
      {React.Children.toArray(children)}
    </motion.button>
  )
})`
  background: none;
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 14px;
  cursor: pointer;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  ${props => props.hidden && css`
    display: block;
    opacity: 0;
    visibility: hidden;
  `}

  ${media.medium`
    width: 21px;
    height: 12px;
  `}
`;
